/* Mostly used in Alerts and some Editor zones */

.kiss-RichText {
  --font: GeneralSans, Helvetica, Arial, sans-serif;
  --text-basis: 16;
  --px-to-rem: calc(1rem / var(--text-basis));

  font-family: var(--font, 'GeneralSans,Helvetica,Arial,sans-serif');
  font-size: calc(18 * var(--px-to-rem));
  font-weight: 400;
  line-height: 1.6;
}

@media (min-width: 640px) {
  .kiss-RichText {
    font-size: calc(20 * var(--px-to-rem));
    line-height: 1.6;
  }
}

.kiss-RichText p {
  font-family: var(--font, 'GeneralSans,Helvetica,Arial,sans-serif');
  font-size: calc(18 * var(--px-to-rem));
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: calc(10 * var(--px-to-rem));
  margin-top: calc(10 * var(--px-to-rem));
}

@media (min-width: 640px) {
  .kiss-RichText p {
    font-size: calc(20 * var(--px-to-rem));
    line-height: 1.6;
  }
}

.kiss-RichText a {
  color: var(--color-link, #1f93ff);
  transition: color .4s;
}

.kiss-RichText a:focus,
.kiss-RichText a:hover {
  color: var(--color-link-hover, #004c92);
}

.kiss-RichText b,
.kiss-RichText strong {
  font-weight: 600;
}

.kiss-RichText i,
.kiss-RichText em {
  font-style: italic;
}

.kiss-RichText u {
  text-decoration: underline;
}

.kiss-RichText h2 {
  font-family: var(--font, 'GeneralSans,Helvetica,Arial,sans-serif');
  font-size: calc(28 * var(--px-to-rem));
  font-weight: 600;
  letter-spacing: .0calc(1 * var(--px-to-rem));
  line-height: 1.2;
  margin-bottom: calc(10 * var(--px-to-rem));
  margin-top: calc(20 * var(--px-to-rem));
}

@media (min-width: 640px) {
  .kiss-RichText h2 {
    font-size: calc(36 * var(--px-to-rem));
    line-height: 1.2;
  }
}

.kiss-RichText h3 {
  font-family: var(--font, 'GeneralSans,Helvetica,Arial,sans-serif');
  font-size: calc(24 * var(--px-to-rem));
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: calc(10 * var(--px-to-rem));
  margin-top: calc(20 * var(--px-to-rem));
}

@media (min-width: 640px) {
  .kiss-RichText h3 {
    font-size: calc(28 * var(--px-to-rem));
    line-height: 1.2;
  }
}

.kiss-RichText h4 {
  font-family: var(--font, 'GeneralSans,Helvetica,Arial,sans-serif');
  font-size: calc(20 * var(--px-to-rem));
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: calc(10 * var(--px-to-rem));
  margin-top: calc(20 * var(--px-to-rem));
}

@media (min-width: 640px) {
  .kiss-RichText h4 {
    font-size: calc(24 * var(--px-to-rem));
    line-height: 1.2;
  }
}

.kiss-RichText ol {
  font-weight: 400;
  list-style: decimal;
  margin-bottom: calc(20 * var(--px-to-rem));
  margin-left: calc(20 * var(--px-to-rem));
  margin-top: calc(20 * var(--px-to-rem));
}

.kiss-RichText ol li {
  line-height: 1.5;
  margin-bottom: calc(10 * var(--px-to-rem));
  margin-top: calc(10 * var(--px-to-rem));
}

.kiss-RichText ol li > div {
  display: unset;
}

.kiss-RichText ul {
  font-family: var(--font, 'GeneralSans,Helvetica,Arial,sans-serif');
  font-weight: 400;
  list-style: none;
  margin: calc(20 * var(--px-to-rem)) 0;
  padding: 0;
  text-align: left;
}

.kiss-RichText ul li {
  display: block;
  margin: calc(5 * var(--px-to-rem)) calc(1 * var(--px-to-rem));
}

.kiss-RichText ul li:before {
  background-color: #222;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  display: inline-block;
  height: calc(6 * var(--px-to-rem));
  margin-left: calc(-1 * var(--px-to-rem));
  margin-right: calc(10 * var(--px-to-rem));
  vertical-align: middle;
  width: calc(6 * var(--px-to-rem));
}

.kiss-RichText ul li > div {
  display: unset;
}

.kiss-RichText img {
  display: block !important;
  max-width: 100% !important;
  height: auto !important;
  display: inline-block;
}

.kiss-RichText iframe,
.kiss-RichText object {
  width: 100%;
}

.kiss-RichText blockquote {
  border-left: calc(4 * var(--px-to-rem)) solid var(--color-border, #eee);
  font-style: italic;
  margin-bottom: calc(20 * var(--px-to-rem));
  margin-top: calc(20 * var(--px-to-rem));
  padding-left: calc(20 * var(--px-to-rem));
}

.kiss-RichText blockquote p+span[data-offset-key]>span[data-text] {
  display: none;
}

.kiss-RichText hr {
  border: 2px solid var(--color-border, #eee);
  float: none;
  margin-bottom: calc(20 * var(--px-to-rem));
  margin-top: calc(20 * var(--px-to-rem));
  width: auto;
}

.kiss-RichText>:last-child {
  margin-bottom: 0;
}

.kiss-RichText figure {
  margin: calc(50 * var(--px-to-rem)) 0;
}

.kiss-RichText .draft-align-center div {
  text-align: center;
}

.kiss-RichText .draft-align-left div {
  text-align: left;
}

.kiss-RichText .draft-align-right div {
  text-align: right;
}

.kiss-RichText .public-DraftStyleDefault-block {
  white-space: pre-line;
}

/* GeneralSans-Regular */
@font-face {
  font-family: 'GeneralSans';
  font-weight: 400;
  font-display: fallback;
  font-style: normal;
  src: url('./fonts/GeneralSans-Regular.woff2') format('woff2'),
       url('./fonts/GeneralSans-Regular.woff') format('woff');
}

/* GeneralSans-Medium */
@font-face {
  font-family: 'GeneralSans';
  font-weight: 500;
  font-display: fallback;
  font-style: normal;
  src: url('./fonts/GeneralSans-Medium.woff2') format('woff2'),
       url('./fonts/GeneralSans-Medium.woff') format('woff');
}

/* GeneralSans-Bold */
@font-face {
  font-family: 'GeneralSans';
  font-weight: 700;
  font-display: fallback;
  font-style: normal;
  src: url('./fonts/GeneralSans-Semibold.woff2') format('woff2'),
       url('./fonts/GeneralSans-Semibold.woff') format('woff');
}

/* Antique-Olive */
@font-face {
  font-family: 'AntiqueOlive';
  font-weight: 600;
  font-display: fallback;
  src: url('./fonts/AntiqueOlive-Bold.woff2') format('woff2'),
       url('./fonts/AntiqueOlive-Bold.woff') format('woff');
}

body {
  color: var(--color-text, #222);
  position: relative;
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text, #222);
  word-wrap: normal;
}

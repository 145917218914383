/* Kiss-specific basic reset */

html {
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  overscroll-behavior: none;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

form {
  padding: 0;
}

/** https://fvsch.com/styling-buttons/#reset
 * Reset button styles.
 * It takes a bit of work to achieve a neutral look.
 */
button {
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
}

abbr {
  text-decoration: none;
}

/* Img */

img[alt]:not([alt='']) {
  /* Only select images with a non-empty alt */
  font-family: GeneralSans;
  font-weight: lighter;
  font-size: 12px;
}

img[alt]:not([alt=''])::before {
  /* More code for Firefox */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2em;
}
